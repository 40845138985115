<template>
    <div class="sidebar-sub-menu-item">
        <div v-if="open" class="sidebar-sub-menu-item">
            <div class="sidebar-sub-menu-open">
                <div @click.prevent="toggleMenu" style="padding: 10px; cursor: pointer">
                    <span style="margin-left: 10px"><icons class="me-3" name="credit_card"/> Pricing Plan</span>
                </div>
                <hr style="margin: 0">

                <div class="sidebar-button">
                  <div
                      @click.prevent="handleClick('team')"
                      class="sub_btn"
                      :class="active === 'team' ? 'active' : ''"
                  >
                    Team
                  </div>
                    <div
                        @click.prevent="handleClick('personal')"
                        class="sub_btn"
                        :class="active === 'personal' ? 'active' : ''"
                    >
                        Personal
                    </div>
                </div>
            </div>
        </div>

        <div @click.prevent="toggleMenu" v-else style="display: flex;justify-content: space-between;align-items: center; cursor: pointer;" >
            <div>
                <span style="margin-left: 10px"><icons class="me-3" name="credit_card"/> Pricing Plan</span>
            </div>
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 1L7 7L1 1" stroke="#092C4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div
            :class="active === 'cards' ? 'card-active' : ''"
            @click.prevent="handleClick('cards')"
            style="display: flex;justify-content: space-between;align-items: center; cursor: pointer; margin-top: 15px"
        >
            <div>
                <span style="margin-left: 10px">
                    <icons class="me-3" :fill="this.$route.query.menu === 'cards' ? '#ffffff' : '#092c4c'" name="save"/> Saved Credit Cards</span>
            </div>
        </div>
    </div>
</template>

<script>
import Icons from "@/components/common/Icons";

export default {
    name: "SubMenu",
    props: ["open", "active"],
    components: {
        Icons
    },
    methods: {
        toggleMenu() {
            this.$emit("toggleMenu");
        },
        handleClick(active) {
            if (active === 'cards') {
                this.$emit("handleClick", ['cards', 'cards'])
            } else {
                this.$emit("handleClick", ['plan', active]);
            }

        }
    },
}
</script>

<style scoped lang="scss">
.sidebar-sub-menu-open {
    border: 1px solid rgba(47, 128, 237, 0.1);
    background: rgba(47, 128, 237, 0.05);
    border-radius: 10px;

    .sidebar-button {
        padding: 10px;

        .sub_btn {
            display: block;
            text-align: right;
            color: #092C4C;
            padding: 10px;
            cursor: pointer;

            &.active {
                color: #FFFFFF;
                background: #00B050;
                border-radius: 5px;
            }
        }
    }
}

.card-active {
    color: #FFFFFF;
    background: #00B050;
    border-radius: 5px;
    padding: 10px;
}

</style>