<template>
  <div class="container-fluid p-0">
      <div class="page-headermb-3">
          <h2 class="main-content-title">Settings</h2>
      </div>
      <div class="card">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-12 col-lg-12 col-xxl-12">
                      <div>
                          <saved-credit-cards/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import SubMenu from "../../components/pricing/SubMenu.vue";
import SavedCreditCards from "../../components/pricing/SavedCreditCards";
import axios from "axios";

export default {
  name: "Index",
  components: {
      SavedCreditCards,
      SubMenu,
  },
  data() {
      return {
          openPlanMenu: false,
          menu: 'plan',
          submenu: 'personal'
      };
  },
  mounted() {
  },
  methods: {
      toggleMenu() {
          this.openPlanMenu = !this.openPlanMenu;
          this.$router.push(`/pricing?menu=plan&submenu=personal`);
      },
  },
};
</script>

<style scoped>
</style>